<script>
import { mapState, mapActions } from 'vuex'
import Input from '../components/Input'
import InputByMapType from '../components/InputByMapType'
import MarzipanoInput from '../components/MarzipanoInput'
export default {
    components: {
        Input, InputByMapType, MarzipanoInput
    },
    computed: {
        ...mapState(['project', 'mapboxProjectData', 'marzipanoCurrentLote', 'mapboxCurrentLote'])

    },
    methods: {
        ...mapActions(['fetchProject', 'setMarzipanoCurrentLote', 'setMapboxCurrentLote', 'updateMapboxLoteInfo', 'updateMarzipanoLoteInfo']),
        async process() {
            switch (this.$route.params.lotizationType) {
                case 'marzipano':
                    await this.updateMarzipanoLoteInfo({
                        projectId: this.$route.params.projectId,
                        lote: this.marzipanoCurrentLote,
                        loteId: this.$route.params.id,
                    })
                    break
                case 'mapbox':
                    this.updateMapboxLoteInfo({
                        projectId: this.$route.params.projectId,
                        lote: this.mapboxCurrentLote,
                        loteId: this.$route.params.id,
                    })
                    break;
                default:
                // TODO

            }
        },
        async loadLote() {
            switch (this.$route.params.lotizationType) {
                case 'marzipano':
                    await this.setMarzipanoCurrentLote({
                        id: this.$route.params.id,
                        projectId: this.$route.params.projectId
                    })
                    break;
                case 'mapbox':
                    await this.setMapboxCurrentLote({
                        id: this.$route.params.id,
                        projectId: this.$route.params.projectId
                    })
                    break;
                default:
                // TODO
            }
        }
    },
    async created() {
        this.loadLote()

        await this.fetchProject({ projectId: this.$route.params.projectId })
        // this.loadLote()
        // this.setHotspot({ id: this.$route.params.id, source: this.$route.params.source, typeProject: this.$route.params.typeProject });
        // this.setCurrentRoute('edit');
    }
}
</script>

<template>
    <div class="container">

        <div v-if="project != null">
            <h2 class="pt-2">{{ project.name }}</h2>
            <span>{{ project.description }}</span>
            <br>
            <span>{{ project.client }}</span>
        </div>

        <hr>

        <div v-if="marzipanoCurrentLote !== null && this.$route.params.lotizationType == 'marzipano'">
            <h4 v-if="marzipanoCurrentLote.manzana != ''" class="c-color">Manzana: <strong>{{
                marzipanoCurrentLote.manzana
            }}</strong></h4>
            <h4 class="c-color"> Lote: <strong>{{
                marzipanoCurrentLote.lote
            }}</strong></h4>


            <form @submit.prevent="process">
                <div>
                    <MarzipanoInput :hotspot="marzipanoCurrentLote" />
                </div>
            </form>
        </div>

        <div v-if="mapboxCurrentLote !== null && this.$route.params.lotizationType == 'mapbox'">
            <h4 v-if="mapboxCurrentLote.manzana != ''" class="c-color">Manzana: <strong>{{
                mapboxCurrentLote.manzana
            }}</strong></h4>
            <h4 class="c-color"> Lote: <strong>{{
                mapboxCurrentLote.properties.lote
            }}</strong></h4>


            <form @submit.prevent="process">
                <div>
                    <InputByMapType :hotspot="mapboxCurrentLote" />
                </div>
            </form>
        </div>
    </div>
</template>
